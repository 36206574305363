import React, {Fragment, Suspense, lazy, useState, useEffect} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ProjIcon from './Icons/ProjIcon'
import CaseIcon from './Icons/CaseIcon'
import EmailIcon from './Icons/EmailIcon'
import PhoneIcon from './Icons/PhoneIcon'
import AudioIcon from './Icons/AudioIcon'
import Modal from 'react-bootstrap/Modal'
import FxFullIcon from './Icons/FxFullIcon'
import ScreenIcon from './Icons/ScreenIcon'
import useWidth from './Functions/useWidth'
import RepairIcon from './Icons/RepairIcon'
import MonitorIcon from './Icons/MonitorIcon'
import ServiceIcon from './Icons/ServiceIcon'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/esm/Button'
import CardDeck from 'react-bootstrap/CardDeck'
import JumboTron from 'react-bootstrap/Jumbotron'
import InventoryIcon from './Icons/InventoryIcon'
import TechnicianIcon from './Icons/TechnicianIcon'
import 'react-placeholder/lib/reactPlaceholder.css'

const TmsPic = lazy(() => import('./Components/TmsPic'))
const AmpPic = lazy(() => import('./Components/AmpPic'))
const CasePic = lazy(() => import('./Components/CasePic'))
const HowToPic = lazy(() => import('./Components/HowToPic'))
const KeysPic = lazy(() => import('./Components/KeysPic.js'))
const FilterPic = lazy(() => import('./Components/FilterPic'))
const ServerPic = lazy(() => import('./Components/ServerPic'))
const HowToTwoPic = lazy(() => import('./Components/HowToTwo.js'))
const ControlPic = lazy(() => import('./Components/ControlPic.js'))
const InventoryPic = lazy(() => import('./Components/InventoryPic'))
const MonitoringPic = lazy(() => import('./Components/MonitoringPic'))
const MonitorPicTwo = lazy(() => import('./Components/MonitorPicTwo.js'))
const ContactFormModal = lazy(() => import('./Components/ContactFormModal'))

const Home = () => {

  const width = useWidth()
  const [host, setHost] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showContactForm, setShowContactForm] = useState(false)

  useEffect(() => {
    const getHost = async () => {
      const response = await axios.post('/getHost')
      setHost(response.data.host)
      setIsLoading(false)
    }
    getHost()
  }, [])

  return (
    <Fragment>
      <Modal show={showContactForm} onHide={() => setShowContactForm(false)} centered>
        <Suspense fallback={<Fragment/>}>
          <ContactFormModal 
            closeFunction={() => setShowContactForm(false)}
          />
        </Suspense>
      </Modal>
      {isLoading && 
        <Col style={{paddingTop:'1em'}} lg={{ span: 1, offset: 5 }} md={{ span: 1, offset: 5 }} xs={{span:1, offset:5}}>
          <Spinner style={{color:'#FFA500', marginBottom:'.5em', height:'1em', width:'1em'}} animation="border" />
        </Col>
      }
      {!isLoading &&
        <>
          {(host === 'doantheatreservices.com' || host === 'doantheaterservices.com') &&
            <>
               {width >= 1140 ?
                <div style={{marginLeft:'2.5em', marginRight:'2.5em'}}>
                  <JumboTron style={{backgroundColor:'#1f1e1e', borderRadius:'1.3em', marginTop:'1em', height:'19em'}}>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <div style={{
                              paddingLeft:'1em',
                              paddingRight:'1em',
                              borderRadius:'1.3em',
                              backgroundColor:'#1f1e1e',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              width:'90%'
                            }}> 
                              <span className='logoFont' style={{fontSize:'2.5em'}}>
                                <span style={{paddingRight:'.25em'}}>
                                  <ScreenIcon height='1em' width='1em' fill='#FFA500'/>
                                </span>Doan Theatre Services
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{marginTop:'1em'}}>
                          <Col>
                            <h4 style={{
                              color:'yellow',
                              margin:'auto',
                              width:'50%',
                              textAlign:'center',
                              fontStyle:'italic'
                            }}>
                              Digital Cinema installation, repair, and software
                          </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <div>
                          <Card.Body style={{color:'#d8d8de'}}>
                            <span style={{color:'#FFA500', fontSize:'1.1em'}}>
                              Doan Theatre Services is a Columbus, OH based company that installs and services digital cinema projection and sound equipment. Our software helps theatres and other digital cinema service providers keep on top of projection related issues.
                            </span>
                            <br/>
                            <br/>
                            <span style={{color:'#d8d8de', fontSize:'1.2em', fontStyle:'italic'}}>
                              Contact us:
                            </span>
                            &emsp;
                            <PhoneIcon height='1.5em' width='1.5em' fill='#FFA500'/>
                            &thinsp;<a style={{color:'cyan', fontSize:'.9em'}} href='tel:6145704420'>1 (614) 570-4420</a>
                            &emsp;
                            <EmailIcon height='1.5em' width='1.5em' fill='#FFA500'/>
                            <Button style={{border:'none', backgroundColor:'transparent', color:'cyan', fontSize:'.9em'}} onClick={
                              showContactForm ?
                              () => setShowContactForm(false) :
                              () => setShowContactForm(true)
                            }>Contact Form</Button>
                          </Card.Body>
                        </div>
                      </Col>
                    </Row>
                  </JumboTron>
                  <Row style={{marginTop:'1.0em', marginBottom:'1.0em'}}>
                    <Col>
                      <CardDeck>
                        <Card style={{
                          borderRadius:'1.2em',
                          backgroundColor:'#1f1e1e'
                        }}>
                          <Card.Header>
                            <Card.Title style={{color:'yellow', fontSize:'1.4em'}}>Services</Card.Title>
                          </Card.Header>
                          <Card.Body style={{color:'#d8d8de', textAlign:'justify'}}>
                            <div style={{
                              marginBottom:'1.5em'
                            }}>
                              <span style={{
                                fontStyle:'italic'
                              }}>
                                From the simple to the complex, we can install, repair, and maintain your digital cinema projection and sound equipment.  
                              </span>
                            </div>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <ProjIcon height='2.25em' width='2.25em' fill='yellow'/>&ensp;Cinema projector installation and repair
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <ServerPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>We install and repair Christie, Barco, and NEC projectors</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  We have over 100 years of cinema experience on staff, and have installed hundreds of digital cinema projectors, including the latest RGB laser projectors. Or if you need a light engine changed or other repair, we can help.
                                </span>
                              </div>
                            </div>
                            <br/>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <AudioIcon height='2.25em' width='2.25em' fill='yellow'/>&ensp;Cinema audio installation and repair
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <AmpPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>From a simple 5.1 system to Dolby Atmos, we can handle any cinema audio system</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  Our trained technicians can install new audio equipment or troubleshoot your existing sound problems. We work with brands lile Dolby, QSC, Crown, and JBL.
                                </span>
                              </div>
                            </div>
                            <br/>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <ServiceIcon height='2.25em' width='2.25em' fill='yellow'/>&ensp;TMS/LMS installation and maintenance
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <TmsPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>We are trained in GDC-TMS and Comscore ACE installations</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  We can install and maintain your theatre management software, saving you time with booth operations.
                                </span>
                              </div>
                            </div>
                            <br/>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <RepairIcon height='2.25em' width='2.25em'fill='yellow'/>&ensp;Preventive maintenance
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <FilterPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>We can help you stop your filters looking like this one.</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  Our maintenance services include equipment cleaning, filter changes, software updates, and quality checks.
                                </span>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card style={{
                          borderRadius:'1.2em',
                          backgroundColor:'#1f1e1e'
                        }}>
                          <Card.Header>
                            <Card.Title style={{color:'yellow', fontSize:'1.4em'}}>Software</Card.Title>
                          </Card.Header>
                          <Card.Body style={{color:'#d8d8de'}}>
                            <div style={{
                              marginBottom:'1.5em'
                            }}>
                              <span style={{
                                fontStyle:'italic'
                              }}>
                                Whether you are a theatre owner or a digital cinema service provider who wants to provide additional services for their customers, our software can help keep you on screen and your presentations top notch. 
                              </span>
                            </div>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <CaseIcon height='2.25em' width='2.25em'fill='yellow'/>&ensp;Case management
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <CasePic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>Keep on top of your projection issues</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  Our case management software provides resources to keep a record of your equipment problems and maintenance history as well as keeping all interested parties aware of the state of the your equipment.
                                </span>
                              </div>
                            </div>
                            <br/>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <MonitorIcon height='2.25em' width='2.25em' fill='yellow'/>&ensp;Remote equipment monitoring
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <MonitoringPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>You can receive emails and/or texts containing notifications from your equipment.</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  Our remote monitoring software keeps you connected to your theatre with owner customizable settings that allow you to control the communication you receive from your equipment. It can also email or text troubleshooting support instantly to the theatre management to help get problems resolved quickly.
                                </span>
                              </div>
                            </div>
                            <br/>
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <InventoryIcon height='2.25em' width='2.25em' fill='yellow'/>&ensp;Inventory management
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <InventoryPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>Keep track of your spare parts, equipment, filters, and bulbs.</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  Our inventory managment software helps you keep track of your projection booth inventory for your theatres. If you have muitple locations, you can also keep an inventory of the home office or for individaul technicians if you are a digital cinema service provider. 
                                </span>
                              </div>
                            </div>
                            <br/>  
                            <div style={{
                              marginTop:'.5em',
                              marginBotton:'1.5em',
                              backgroundColor:'#2e2d2d',
                              padding:'.75em',
                              borderRadius:'.75em'
                            }}>
                              <div style={{
                                marginBottom:'.5em'
                              }}>
                                <span
                                  style={{
                                    fontSize:'1.3em',
                                    color:'yellow'
                                  }}
                                >
                                  <TechnicianIcon height='2.25em' width='2.25em' fill='yellow'/>&ensp;Online and remote support
                                </span>
                              </div>
                              <div>
                                <Suspense fallback={<Fragment/>}>
                                  <HowToPic />
                                </Suspense>
                                <div style={{
                                  marginTop:'.5em',
                                  marginLeft:'.5em'
                                }}>
                                  <span style={{color:'#FFA500'}}>Quickly lookup training and troubleshooting help</span>
                                </div>
                              </div>
                              <div style={{
                                marginTop:'2em',
                                marginBottom:'.5em'
                              }}>
                                <span>
                                  Theatre management can lookup written or video troubleshooting help for common problems or they contact one of our technicians for help.
                                </span>
                              </div>
                            </div>
                          </Card.Body> 
                        </Card>
                      </CardDeck>
                    </Col>
                  </Row>
                </div> :
                width <= 480 ?
                <Fragment>
                  <div style={{marginLeft:'2.5em', marginRight:'2.5em'}}>
                    <JumboTron style={{backgroundColor:'#1f1e1e', borderRadius:'1.3em', marginTop:'1em'}}>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}> 
                            <span className='logoFont' style={{fontSize:'1.75em'}}>
                                Doan Theatre Services
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em'}}>
                        <Col>
                          <h6 style={{color:'yellow', fontSize:'.8em', fontStyle:'italic', fontWeight:'500'}}>
                            Digital cinema installation, repair, and software
                          </h6>
                        </Col>
                      </Row> 
                      <Row style={{marginTop:'1em'}}>
                        <Col>
                          <span style={{color:'#FFA500', fontSize:'.8em'}}>
                          Doan Theatre Services is a Columbus, OH based company that installs and services digital cinema projection and sound equipment. Our software helps theatres and other digital cinema service providers keep on top of projection related issues.
                          </span>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em'}}>
                        <Col>
                          <Card.Body style={{color:'#d8d8de'}}>
                            <br/>
                            <span style={{color:'#d8d8de', fontSize:'1em', fontStyle:'italic'}}>
                              Contact us:
                            </span>
                            <br/>
                            <br/>
                            <PhoneIcon height='1.25em' width='1.25em' fill='#FFA500'/>
                            &emsp;<a style={{color:'cyan', fontSize:'.8em'}} href='tel:6145704420'>1 (614) 570-4420</a>
                            <br/>
                            <EmailIcon height='1.25em' width='1.25em' fill='#FFA500'/>
                            <Button style={{border:'none', backgroundColor:'transparent', color:'cyan', fontSize:'.8em'}} onClick={
                              showContactForm ?
                              () => setShowContactForm(false) :
                              () => setShowContactForm(true)
                            }>Contact Form</Button>
                          </Card.Body>
                        </Col>
                      </Row>
                    </JumboTron>
                    <Row style={{marginTop:'1.0em', marginBottom:'1.0em'}}>
                      <Col>
                        <CardDeck>
                          <Card style={{
                            borderRadius:'1.2em',
                            backgroundColor:'#1f1e1e'
                          }}>
                            <Card.Header>
                              <Card.Title style={{color:'yellow', fontSize:'1.4em'}}>Services</Card.Title>
                            </Card.Header>
                            <Card.Body style={{color:'#d8d8de', textAlign:'justify'}}>
                              <div style={{
                                marginBottom:'1.5em'
                              }}>
                                <span style={{
                                  fontStyle:'italic',
                                  fontSize:'.9em'
                                }}>
                                  From the simple to the complex, we can install, repair, and maintain your digital cinema projection and sound equipment.  
                                </span>
                              </div>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <ProjIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Projector installation and repair
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <ServerPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>We install and repair Christie, Barco, and NEC projectors</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    We have over 100 years of cinema experience on staff, and have installed hundreds of digital cinema projectors, including the latest RGB laser projectors. Or if you need a light engine changed or other repair, we can help.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <AudioIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Audio installation and repair
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <AmpPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>From a simple 5.1 system to Dolby Atmos, we can handle any cinema audio system</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our trained technicians can install new audio equipment or troubleshoot your existing sound problems. We work with brands lile Dolby, QSC, Crown, and JBL.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <ServiceIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;TMS/LMS installation
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <TmsPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>We are trained in GDC-TMS and Comscore ACE installations</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    We can install and maintain your theatre management software, saving you time with booth operations.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <RepairIcon height='1.75em' width='1.75em'fill='yellow'/>&ensp;Preventive maintenance
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <FilterPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>We can help you stop your filters looking like this one.</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our maintenance services include equipment cleaning, filter changes, software updates, and quality checks.
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                          <Card style={{
                            borderRadius:'1.2em',
                            backgroundColor:'#1f1e1e'
                          }}>
                            <Card.Header>
                              <Card.Title style={{color:'yellow', fontSize:'1.4em'}}>Software</Card.Title>
                            </Card.Header>
                            <Card.Body style={{color:'#d8d8de'}}>
                              <div style={{
                                marginBottom:'1.5em'
                              }}>
                                <span style={{
                                  fontStyle:'italic',
                                  fontSize:'.9em'
                                }}>
                                  Whether you are a theatre owner or a digital cinema service provider who wants to provide additional services for their customers, our software can help keep you on screen and your presentations top notch. 
                                </span>
                              </div>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <CaseIcon height='1.75em' width='1.75em'fill='yellow'/>&ensp;Case management
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <CasePic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>Keep on top of your projection issues</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our case management software provides resources to keep a record of your equipment problems and maintenance history as well as keeping all interested parties aware of the state of the your equipment.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <MonitorIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Remote equipment monitoring
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <MonitoringPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>You can receive emails and/or texts containing notifications from your equipment.</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our remote monitoring software keeps you connected to your theatre with owner customizable settings that allow you to control the communication you receive from your equipment. It can also email or text troubleshooting support instantly to the theatre management to help get problems resolved quickly.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <InventoryIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Inventory management
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <InventoryPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>Keep track of your spare parts, equipment, filters, and bulbs.</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our inventory managment software helps you keep track of your projection booth inventory for your theatres. If you have muitple locations, you can also keep an inventory of the home office or for individaul technicians if you are a digital cinema service provider. 
                                  </span>
                                </div>
                              </div>
                              <br/>  
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <TechnicianIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Online and remote support
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <HowToPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>Quickly lookup training and troubleshooting help</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Theatre management can lookup written or video troubleshooting help for common problems or they contact one of our technicians for help.
                                  </span>
                                </div>
                              </div>
                            </Card.Body> 
                          </Card>
                        </CardDeck>
                      </Col>
                    </Row>
                  </div> :
                </Fragment> :
                //midsize
                <Fragment>
                  <div style={{marginLeft:'2.5em', marginRight:'2.5em'}}>
                    <JumboTron style={{backgroundColor:'#1f1e1e', borderRadius:'1.3em', marginTop:'1em'}}>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}> 
                            <span className='logoFont' style={{fontSize:'2.5em'}}>
                              <span style={{paddingRight:'.25em'}}>
                                <ScreenIcon height='1em' width='1em' fill='#FFA500'/>
                              </span>Doan Theatre Services
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'1em'}}>
                        <Col>
                          <h4 style={{
                            color:'yellow',
                            margin:'auto',
                            width:'50%',
                            textAlign:'center',
                            fontStyle:'italic'
                          }}>
                            Digital Cinema installation, repair, and software
                        </h4>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em'}}>
                      <Col>
                          <div>
                            {width > 675 ?
                              <Card.Body style={{color:'#d8d8de'}}>
                                <span style={{color:'#FFA500', fontSize:'1.1em'}}>
                                  Doan Theatre Services is a Columbus, OH based company that installs and services digital cinema projection and sound equipment. Our software helps theatres and other digital cinema service providers keep on top of projection related issues.
                                </span>
                                <br/>
                                <br/>
                                <span style={{color:'#d8d8de', fontSize:'1.2em', fontStyle:'italic'}}>
                                  Contact us:
                                </span>
                                &emsp;
                                &emsp;
                                <PhoneIcon height='1.5em' width='1.5em' fill='#FFA500'/>
                                &emsp;<a style={{color:'cyan'}} href='tel:6145704420'>1 (614) 570-4420</a>
                                &emsp;
                                &emsp;
                                <EmailIcon height='1.5em' width='1.5em' fill='#FFA500'/>
                                <Button style={{border:'none', backgroundColor:'transparent', color:'cyan'}} onClick={
                                  showContactForm ?
                                  () => setShowContactForm(false) :
                                  () => setShowContactForm(true)
                                }>Contact Form</Button>
                              </Card.Body> :
                              <Card.Body style={{color:'#d8d8de'}}>
                                <span style={{color:'#FFA500', fontSize:'1.1em'}}>
                                Doan Theatre Services is a Columbus, OH based company that installs and services digital cinema projection and sound equipment. Our software helps theatres and other digital cinema service providers keep on top of projection related issues. 
                                </span>
                                <br/>
                                <br/>
                                <span style={{color:'#d8d8de', fontSize:'1.2em', fontStyle:'italic'}}>
                                  Contact us:
                                </span>
                                <br/>
                                <br/>
                                <PhoneIcon height='1.5em' width='1.5em' fill='#FFA500'/>
                                &emsp;<a style={{color:'cyan'}} href='tel:6145704420'>1 (614) 570-4420</a>
                                <br/>
                                <EmailIcon height='1.5em' width='1.5em' fill='#FFA500'/>
                                <Button style={{border:'none', backgroundColor:'transparent', color:'cyan'}} onClick={
                                  showContactForm ?
                                  () => setShowContactForm(false) :
                                  () => setShowContactForm(true)
                                }>Contact Form</Button>
                              </Card.Body>
                            }
                          </div>
                        </Col>
                      </Row>
                    </JumboTron>
                    <Row style={{marginTop:'1.0em', marginBottom:'1.0em'}}>
                      <Col>
                        <CardDeck>
                          <Card style={{
                            borderRadius:'1.2em',
                            backgroundColor:'#1f1e1e'
                          }}>
                            <Card.Header>
                              <Card.Title style={{color:'yellow', fontSize:'1.4em'}}>Services</Card.Title>
                            </Card.Header>
                            <Card.Body style={{color:'#d8d8de', textAlign:'justify'}}>
                              <div style={{
                                marginBottom:'1.5em'
                              }}>
                                <span style={{
                                  fontStyle:'italic'
                                }}>
                                  From the simple to the complex, we can install, repair, and maintain your digital cinema projection and sound equipment.  
                                </span>
                              </div>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <ProjIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Projector installation and repair
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <ServerPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>We install and repair Christie, Barco, and NEC projectors</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    We have over 100 years of cinema experience on staff, and have installed hundreds of digital cinema projectors, including the latest RGB laser projectors. Or if you need a light engine changed or other repair, we can help.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <AudioIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Audio installation and repair
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <AmpPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>From a simple 5.1 system to Dolby Atmos, we can handle any cinema audio system</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our trained technicians can install new audio equipment or troubleshoot your existing sound problems. We work with brands lile Dolby, QSC, Crown, and JBL.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <ServiceIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;TMS/LMS installation
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <TmsPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>We are trained in GDC-TMS and Comscore ACE installations</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    We can install and maintain your theatre management software, saving you time with booth operations.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <RepairIcon height='1.75em' width='1.75em'fill='yellow'/>&ensp;Preventive maintenance
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <FilterPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>We can help you stop your filters looking like this one.</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our maintenance services include equipment cleaning, filter changes, software updates, and quality checks.
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                          <Card style={{
                            borderRadius:'1.2em',
                            backgroundColor:'#1f1e1e'
                          }}>
                            <Card.Header>
                              <Card.Title style={{color:'yellow', fontSize:'1.4em'}}>Software</Card.Title>
                            </Card.Header>
                            <Card.Body style={{color:'#d8d8de'}}>
                              <div style={{
                                marginBottom:'1.5em'
                              }}>
                                <span style={{
                                  fontStyle:'italic'
                                }}>
                                  Whether you are a theatre owner or a digital cinema service provider who wants to provide additional services for their customers, our software can help keep you on screen and your presentations top notch. 
                                </span>
                              </div>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <CaseIcon height='1.75em' width='1.75em'fill='yellow'/>&ensp;Case management
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <CasePic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>Keep on top of your projection issues</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our case management software provides resources to keep a record of your equipment problems and maintenance history as well as keeping all interested parties aware of the state of the your equipment.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <MonitorIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Remote equipment monitoring
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <MonitoringPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>You can receive emails and/or texts containing notifications from your equipment.</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our remote monitoring software keeps you connected to your theatre with owner customizable settings that allow you to control the communication you receive from your equipment. It can also email or text troubleshooting support instantly to the theatre management to help get problems resolved quickly.
                                  </span>
                                </div>
                              </div>
                              <br/>
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <InventoryIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Inventory management
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <InventoryPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>Keep track of your spare parts, equipment, filters, and bulbs.</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Our inventory managment software helps you keep track of your projection booth inventory for your theatres. If you have muitple locations, you can also keep an inventory of the home office or for individaul technicians if you are a digital cinema service provider. 
                                  </span>
                                </div>
                              </div>
                              <br/>  
                              <div style={{
                                marginTop:'.5em',
                                marginBotton:'1.5em',
                                backgroundColor:'#2e2d2d',
                                padding:'.75em',
                                borderRadius:'.75em'
                              }}>
                                <div style={{
                                  marginBottom:'.5em'
                                }}>
                                  <span
                                    style={{
                                      color:'yellow'
                                    }}
                                  >
                                    <TechnicianIcon height='1.75em' width='1.75em' fill='yellow'/>&ensp;Online and remote support
                                  </span>
                                </div>
                                <div>
                                  <Suspense fallback={<Fragment/>}>
                                    <HowToPic />
                                  </Suspense>
                                  <div style={{
                                    marginTop:'.5em',
                                    marginLeft:'.5em'
                                  }}>
                                    <span style={{color:'#FFA500'}}>Quickly lookup training and troubleshooting help</span>
                                  </div>
                                </div>
                                <div style={{
                                  marginTop:'2em',
                                  marginBottom:'.5em'
                                }}>
                                  <span>
                                    Theatre management can lookup written or video troubleshooting help for common problems or they contact one of our technicians for help.
                                  </span>
                                </div>
                              </div>
                            </Card.Body> 
                          </Card>
                        </CardDeck>
                      </Col>
                    </Row>
                  </div>
                </Fragment>
              }
              <Row>
                <Col style={{
                  alignItems:'center',
                  justifyContent:'center',
                  display:'flex',
                  marginBottom:'1em'
                }}>
                  <Link style={{color:'cyan', fontSize:'.9em'}} to={{pathname:'/terms'}}>Terms of Use</Link>
                </Col>
              </Row>
            </>
          } 
          {(host === 'doannoc.com') &&
            <>
              <div style={{marginLeft:'2.5em', marginRight:'2.5em'}}>
                <JumboTron style={{backgroundColor:'#1f1e1e', borderRadius:'1.3em', marginTop:'1em'}}>
                  <Row>
                    <Col>
                      <div style={{
                        paddingLeft:'1em',
                        paddingRight:'1em',
                        borderRadius:'1.3em',
                        backgroundColor:'#1f1e1e',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width:'90%'
                      }}> 
                        <span className='logoFont' style={{fontSize:'2.5em'}}>
                          <span style={{paddingRight:'.25em'}}>
                            <ScreenIcon height='1em' width='1em' fill='#FFA500'/>
                          </span>Doan NOC
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{
                        paddingLeft:'1em',
                        paddingRight:'1em',
                        borderRadius:'1.3em',
                        backgroundColor:'#1f1e1e',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width:'90%'
                      }}>
                        <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                          Digital cinema software
                        </span>
                      </div> 
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{
                        paddingLeft:'1em',
                        paddingRight:'1em',
                        borderRadius:'1.3em',
                        backgroundColor:'#1f1e1e',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width:'90%'
                      }}>
                        <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                          Case & Maintenance Management - Inventory Management - Remote Monitoring<br/>
                          Equipment Control - Automated KDM Delivery - Online training & troubleshooting  
                        </span>
                      </div> 
                    </Col>
                  </Row>
                </JumboTron>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Case and Maintenance management
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Track your current issues and keep a history<br/>of the work done on your equipment 
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'100%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <CasePic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Inventory management
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Track your bulb, lamp, filter, and equipment inventories 
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'100%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <InventoryPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '.5em',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Remote monitoring
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Receive notifications, get the real time status, and monitor equipment health 
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom:'.5em',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <MonitorPicTwo />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Equipment control
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Use your equipment from anywhere
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom:'.5em',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <ControlPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '.5em',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Automated KDM delivery
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Have your KDM's delivered to your location automatically
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <KeysPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Online training and troubleshooting
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Get access to our library of online videos and instructions
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <HowToTwoPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col style={{
                    alignItems:'center',
                    justifyContent:'center',
                    display:'flex',
                    marginBottom:'1em'
                  }}>
                    <Link style={{color:'cyan', fontSize:'.9em'}} to={{pathname:'/terms'}}>Terms of Use</Link>
                  </Col>
                </Row>
              </div>
            </>
          }
          {(host === 'fxcinemasoftware.com') &&
            <>
              <div style={{marginLeft:'2.5em', marginRight:'2.5em'}}>
                <JumboTron style={{backgroundColor:'#1f1e1e', borderRadius:'1.3em', marginTop:'1em'}}>
                  <Row style={{marginTop:'-8.5em'}}> 
                    <Col>
                      <div style={{
                        display:'flex',
                        justifyContent: 'center',
                        alignItems:'center'
                      }}>
                         <FxFullIcon height='30em' width='30em' fill='#FFA500' />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{marginTop:'-4em'}}>
                    <Col>
                      <div style={{
                        paddingLeft:'1em',
                        paddingRight:'1em',
                        borderRadius:'1.3em',
                        backgroundColor:'#1f1e1e',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width:'90%'
                      }}>
                        <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                          Digital cinema software
                        </span>
                      </div> 
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{
                        paddingLeft:'1em',
                        paddingRight:'1em',
                        borderRadius:'1.3em',
                        backgroundColor:'#1f1e1e',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width:'90%'
                      }}>
                        <span className='logoFont' style={{fontSize:'1.2em', color:'#d8d8de'}}>
                          Case & Maintenance Management - Inventory Management - Remote Monitoring<br/>
                          Equipment Control - Automated KDM Delivery - Quality Control - and much more
                        </span>
                      </div>
                      <br/>
                      <div style={{
                        display:'flex',
                        justifyContent: 'center',
                        alignItems:'center'
                      }}>
                        <Button style={{border:'none', backgroundColor:'transparent', color:'cyan', fontSize:'1.2em'}} onClick={
                            showContactForm ?
                            () => setShowContactForm(false) :
                            () => setShowContactForm(true)
                          }>Contact Us
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </JumboTron>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Case and Maintenance management
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Track your current issues and keep a history<br/>of the work done on your equipment 
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'100%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <CasePic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Inventory management
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Track your bulb, lamp, filter, and equipment inventories 
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'100%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <InventoryPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '.5em',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Remote monitoring
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Beyond just receiving notifications, we have the industries most detailed, comprehensive remote monitioring of digital cinema equipment.
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom:'.5em',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <MonitorPicTwo />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Equipment control
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Use your equipment from anywhere
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom:'.5em',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <ControlPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                  <Col>
                    <Card style={{
                      backgroundColor:'#1f1e1e', 
                      borderRadius:'1.3em',
                      marginTop:'1em', 
                      width:'90%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '.5em',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'2em', color:'#FFA500'}}>
                              Automated KDM delivery
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row style={{marginTop:'.5em', marginBottom:'.5em'}}>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%'
                          }}>
                            <span className='logoFont' style={{fontSize:'1.5em', color:'#d8d8de'}}>
                              Have your KDM's delivered to your location automatically
                            </span>
                          </div> 
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{
                            paddingLeft:'1em',
                            paddingRight:'1em',
                            borderRadius:'1.3em',
                            backgroundColor:'#1f1e1e',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width:'90%',
                            marginBottom:'3em'
                          }}>
                            <Suspense fallback={<Fragment/>}>
                              <KeysPic />
                            </Suspense>
                          </div> 
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col style={{
                    alignItems:'center',
                    justifyContent:'center',
                    display:'flex',
                    marginBottom:'1em'
                  }}>
                    <Link style={{color:'cyan', fontSize:'.9em'}} to={{pathname:'/terms'}}>Terms of Use</Link>
                  </Col>
                </Row>
              </div>
            </>
          }
        </>
      } 
    </Fragment>
  )
}
  
export default Home