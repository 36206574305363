import React, {useEffect, useState, Fragment} from 'react'
import axios from 'axios'
import PMIcon from '../Icons/PMIcon'
import Nav from 'react-bootstrap/Nav'
import styled from 'styled-components'
import RmaIcon from '../Icons/RmaIcon'
import MapIcon from '../Icons/MapIcon'
import KeyIcon from '../Icons/KeyIcon'
import UserIcon from '../Icons/UserIcon'
import MenuIcon from '../Icons/MenuIcon'
import ProjIcon from '../Icons/ProjIcon'
import HomeIcon from '../Icons/HomeIcon'
import CaseIcon from '../Icons/CaseIcon'
import PingIcon from '../Icons/PingIcon'
import MapNav from '../Components/MapNav'
import getUser from '../Functions/getUser'
import TermsIcon from '../Icons/TermsIcon'
import OrderIcon from '../Icons/OrderIcon'
import ImageIcon from '../Icons/ImageIcon'
import AuditIcon from '../Icons/AuditIcon'
import UserNav from '../Components/UserNav'
import Navbar from 'react-bootstrap/Navbar'
import ClientIcon from '../Icons/ClientIcon'
import AccessIcon from '../Icons/AccessIcon'
import CinemaIcon from '../Icons/CinemaIcon'
import DealerIcon from '../Icons/DealerIcon'
import useWidth from '../Functions/useWidth'
import LicenseIcon from '../Icons/LicenseIcon'
import BillingIcon from '../Icons/BillingIcon'
import MonitorIcon from '../Icons/MonitorIcon'
import PackageIcon from '../Icons/PackageIcon'
import FollowUpIcon from '../Icons/FollowUpIcon'
import SolutionIcon from '../Icons/SolutionIcon'
import ProviderIcon from '../Icons/ProviderIcon'
import Container from 'react-bootstrap/Container'
import InventoryIcon from '../Icons/InventoryIcon'
import LogInLogOut from '../Components/LogInLogOut'
import NavbarBrand from 'react-bootstrap/NavbarBrand'
import NavDropdown from 'react-bootstrap/NavDropdown'
import DoanNavLogo from '../Components/DoanNavLogo'
import FxNavLogo from '../Components/FxNavLogo'

const Styles = styled.div`
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  font-family: 'Roboto', sans-serif;

  .navbar { 
    background: #1f1e1e;
  }

  a, .navbar-nav, .navbar-light .nav-link {
    color: #d8d8de;
    &:hover { color: #FFA500; }
  }

  .navbar-brand {
    font-size: 1.4em;
    color: #d8d8de;
    &:hover { color: #FFA500; }
  }

  .icon {
    height: 1.5em;
    width: 1.5em;
    color: #FFA500;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  @media only screen and (min-width:62rem) {
    .logo {
         
    }
    .alignment {
      display: flex;
      justify-content: center;
      align-items: center; 
      padding: 2px 2px 2px 2px;
    }
  }
  @media only screen and (max-width:62rem) {
    .logo {
        width: 60%
    }
    .alignment {
      display: flex;
      justify-content: flex-start;
      align-items: center; 
      padding: 0px 4px 0px 4px;
    }
  }
  
`
const NavigationBar = (props) => {

  const width = useWidth()
  const [host, setHost] = useState('')
  const [userName, setUserName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  
  let user = {role:4}
  if (props.token) {
    user = getUser()
  }

  useEffect(() => {
    const getHostname = async () => {
      const getHost = await axios.post('/getHost')
      setHost(getHost.data.host)
      setIsLoading(false)
    }
    getHostname()
  }, [host])

  useEffect(() => {
    if (user.user) {
      
      const getUserName = async () => {
        try {
          const response = await axios.post('/getUsername', {
            user: user.user
          })
          
          setUserName(response.data.name)
          
        } catch (e) {
          console.log(e)
        }
      }
      getUserName()
    }
  }, [user.user])

  return (
    <Container fluid>
      <Styles>
        <Navbar expand='lg' variant='dark'>
          <Navbar.Toggle aria-controls='basic-navbar-nav'/>
            <NavbarBrand href='/'>
              {!isLoading &&
                <>
                  {(host === 'doantheatreservices.com' || host === 'doantheaterservices.com' || host === 'doannoc.com') ?
                    <DoanNavLogo width={width} /> : <FxNavLogo width={width} />
                  }
                </>
              }
              {userName && props.token && width > 992 &&
                <span style={{marginLeft: '1.75em', marginRight:'.75em', fontSize:'.7em', color:'#FFA500'}}>{`Welcome, ${userName}`}</span>
              }
            </NavbarBrand>
              <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              {props.token && width >= 992 &&
                <NavDropdown 
                  style={{marginRight:'2em'}} 
                  title={<MenuIcon height='1.6em' width='1.6em' fill='#FFA500' />} 
                >
                  <NavDropdown.Item style={{color:'black'}} href='/cases' eventKey="1">
                    <CaseIcon height='1.5em' width='1.5em' />&ensp;Cases
                  </NavDropdown.Item>
                
                  {user.role !== 6 &&
                    <>
                      <NavDropdown.Item style={{color:'black'}} href='/inventory' eventKey="11">
                        <InventoryIcon height='1.5em' width='1.5em' />&ensp;Inventory
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/pm' eventKey="2">
                        <PMIcon height='1.5em' width='1.5em' />&ensp;PM and Calendar
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/monitor' eventKey="12">
                        <MonitorIcon height='1.5em' width='1.5em' />&ensp;Monitor
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/boothPics' eventKey="15">
                        <ImageIcon height='1.5em' width='1.5em' />&ensp;Booth Images
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/keys' eventKey="20">
                        <KeyIcon height='1.5em' width='1.5em' />&ensp;Keys
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/screenaudit' eventKey="21">
                        <AuditIcon height='1.5em' width='1.5em' />&ensp;Screen audit
                      </NavDropdown.Item>
                    </>
                  }
                  <NavDropdown.Item style={{color:'black'}} href='/control' eventKey="13">
                    <AccessIcon height='1.5em' width='1.5em' />&ensp;Control
                  </NavDropdown.Item>
                  <NavDropdown.Item style={{color:'black'}} href='/owners' eventKey="3">
                    <CinemaIcon height='1.5em' width='1.5em' />&ensp;Owners and locations
                  </NavDropdown.Item>
                  <NavDropdown.Item style={{color:'black'}} href='/equipment' eventKey="4">
                    <ProjIcon height='1.5em' width='1.5em' />&ensp;Equipment
                  </NavDropdown.Item>
                  <NavDropdown.Item style={{color:'black'}} href='/orders' eventKey="5">
                    <OrderIcon height='1.5em' width='1.5em' />&ensp;Orders
                  </NavDropdown.Item>
                  <NavDropdown.Item style={{color:'black'}} href='/tracking' eventKey="16">
                    <PackageIcon height='1.5em' width='1.5em' />&ensp;Package tracking
                  </NavDropdown.Item>
                  <NavDropdown.Item style={{color:'black'}} href='/rma' eventKey="22">
                    <RmaIcon height='1.5em' width='1.5em' />&ensp;RMA
                  </NavDropdown.Item>
                  {(user.role === 1 || user.role === 2 || user.role === 8) &&
                    <>
                      <NavDropdown.Item style={{color:'black'}} href='/followup' eventKey="6">
                        <FollowUpIcon height='1.5em' width='1.5em' />&ensp;Follow up
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/locations' eventKey="6">
                        <MapIcon height='1.5em' width='1.5em' />&ensp;Locations Map
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{color:'black'}} href='/solutions' eventKey="17">
                        <SolutionIcon height='1.5em' width='1.5em' />&ensp;Solutions
                      </NavDropdown.Item>
                    </>
                  }
                  {(user.role === 1 || user.role === 3 || user.role === 5 || user.role === 8) &&
                    <NavDropdown.Item style={{color:'black'}} href='/users' eventKey="7">
                      <UserIcon height='1.5em' width='1.5em' />&ensp;Users
                    </NavDropdown.Item>
                  }
                  <NavDropdown.Item style={{color:'black'}} href='/ping' eventKey="23">
                    <PingIcon height='1.5em' width='1.5em' />&ensp;Ping
                  </NavDropdown.Item>
                  {(user.role === 1 || user.role === 8 || user.role === 2) &&
                    <NavDropdown.Item style={{color:'black'}} href='/client' eventKey="14">
                      <ClientIcon height='1.5em' width='1.5em' />&ensp;Clients
                    </NavDropdown.Item>
                  }
                  {(user.role === 1 || user.role === 8) &&
                    <NavDropdown.Item style={{color:'black'}} href='/providers' eventKey="15">
                      <ProviderIcon height='1.5em' width='1.5em' />&ensp;Service Providers
                    </NavDropdown.Item>
                  }
                  <NavDropdown.Item style={{color:'black'}} href='/dealers' eventKey="8">
                    <DealerIcon height='1.5em' width='1.5em' />&ensp;Dealers
                  </NavDropdown.Item>
                  {(user.role === 1 || user.role === 3 || user.role === 5 || user.role === 8) &&
                    <NavDropdown.Item style={{color:'black'}} href='/billing' eventKey="9">
                      <BillingIcon height='1.5em' width='1.5em' />&ensp;Billing
                    </NavDropdown.Item>
                  }
                  {(user.role === 1) &&
                    <NavDropdown.Item style={{color:'black'}} href='/license' eventKey="19">
                      <LicenseIcon height='1.5em' width='1.5em' />&ensp;Licenses
                    </NavDropdown.Item>
                  }
                  <NavDropdown.Item style={{color:'black'}} href='/terms' eventKey="10">
                    <TermsIcon height='1.5em' width='1.5em' />&ensp;Terms of Use
                  </NavDropdown.Item>
                  <NavDropdown.Item style={{color:'black'}} href='/privacy' eventKey="18">
                    <TermsIcon height='1.5em' width='1.5em' />&ensp;Privacy policy
                  </NavDropdown.Item>
                </NavDropdown>
              }
              {userName && props.token &&
                <Nav.Item className='d-lg-none alignment'><span style={{color:'#FFA500'}}>{`Welcome, ${userName}`}</span></Nav.Item>
              }
              {window.location.pathname !== '/' &&
                <Nav.Item className='alignment'><HomeIcon height='1.5em' width='1.5em' fill='#FFA500'></HomeIcon><Nav.Link style={{color:'#d8d8de'}}  href='/'>&ensp;Home</Nav.Link></Nav.Item> 
              }
              <LogInLogOut token={props.token}></LogInLogOut>
              {props.token &&
                <Fragment>
                  <Nav.Item className='d-lg-none alignment'><CaseIcon height='1.5em' width='1.5em' fill='#FFA500'></CaseIcon><Nav.Link style={{color:'#d8d8de'}} href='/cases'>&ensp;Cases</Nav.Link></Nav.Item>
                  {user.role !== 6 &&
                    <>
                      <Nav.Item className='d-lg-none alignment'><InventoryIcon height='1.5em' width='1.5em' fill='#FFA500'></InventoryIcon><Nav.Link style={{color:'#d8d8de'}} href='/inventory'>&ensp;Inventory</Nav.Link></Nav.Item>
                      <Nav.Item className='d-lg-none alignment'><MonitorIcon height='1.5em' width='1.5em' fill='#FFA500'></MonitorIcon><Nav.Link style={{color:'#d8d8de'}} href='/monitor'>&ensp;Monitor</Nav.Link></Nav.Item>
                      <Nav.Item className='d-lg-none alignment'><ImageIcon height='1.5em' width='1.5em' fill='#FFA500' /><Nav.Link style={{color:'#d8d8de'}} href='/boothPics'>&ensp;Booth Images</Nav.Link></Nav.Item>
                      <Nav.Item className='d-lg-none alignment'><PMIcon height='1.5em' width='1.5em' fill='#FFA500'></PMIcon><Nav.Link style={{color:'#d8d8de'}} href='/pm'>&ensp;PM and Schedule</Nav.Link></Nav.Item>
                      <Nav.Item className='d-lg-none alignment'><KeyIcon height='1.5em' width='1.5em' fill='#FFA500'></KeyIcon><Nav.Link style={{color:'#d8d8de'}} href='/keys'>&ensp;Keys</Nav.Link></Nav.Item>
                      <Nav.Item className='d-lg-none alignment'><AuditIcon height='1.5em' width='1.5em' fill='#FFA500'></AuditIcon><Nav.Link style={{color:'#d8d8de'}} href='/screenaudit'>&ensp;Screen audit</Nav.Link></Nav.Item>
                    </>
                  }

                  <Nav.Item className='d-lg-none alignment'><AccessIcon height='1.5em' width='1.5em' fill='#FFA500'></AccessIcon><Nav.Link style={{color:'#d8d8de'}} href='/control'>&ensp;Control</Nav.Link></Nav.Item>
                  <Nav.Item className='d-lg-none alignment'><PingIcon height='1.5em' width='1.5em' fill='#FFA500' /><Nav.Link style={{color:'#d8d8de'}} href='/ping'>&ensp;Ping</Nav.Link></Nav.Item>
                  <Nav.Item className='d-lg-none alignment'><CinemaIcon height='1.5em' width='1.5em' fill='#FFA500'></CinemaIcon><Nav.Link style={{color:'#d8d8de'}} href='/owners'>&ensp;Owners and locations</Nav.Link></Nav.Item>
                  <Nav.Item className='d-lg-none alignment'><ProjIcon height='1.5em' width='1.5em' fill='#FFA500'></ProjIcon><Nav.Link style={{color:'#d8d8de'}} href='/equipment'>&ensp;Equipment</Nav.Link></Nav.Item>
                  <Nav.Item className='d-lg-none alignment'><OrderIcon height='1.5em' width='1.5em' fill='#FFA500'></OrderIcon><Nav.Link style={{color:'#d8d8de'}} href='/orders'>&ensp;Orders</Nav.Link></Nav.Item>
                  <Nav.Item className='d-lg-none alignment'><RmaIcon height='1.5em' width='1.5em' fill='#FFA500'></RmaIcon><Nav.Link style={{color:'#d8d8de'}} href='/rma'>&ensp;RMA</Nav.Link></Nav.Item>
                  <Nav.Item className='d-lg-none alignment'><PackageIcon height='1.5em' width='1.5em' fill='#FFA500' /><Nav.Link style={{color:'#d8d8de'}} href='/tracking'>&ensp;Package Tracking</Nav.Link></Nav.Item>
                  {(user.role === 1 || user.role === 2 || user.role === 8) &&
                    <>
                      <MapNav token={props.token}></MapNav>
                      <Nav.Item className='d-lg-none alignment'><FollowUpIcon height='1.5em' width='1.5em' fill='#FFA500'/><Nav.Link style={{color:'#d8d8de'}} href='/followup'>&ensp;Follow up</Nav.Link></Nav.Item>
                    </>
                  }
                  {(user.role === 1 || user.role === 8 || user.role === 3 || user.role === 5) &&
                    <UserNav token={props.token}></UserNav>
                  }
                  {(user.role === 1 || user.role === 8 || user.role === 2) &&
                    <Nav.Item className='d-lg-none alignment'><ClientIcon height='1.5em' width='1.5em' fill='#FFA500'/><Nav.Link style={{color:'#d8d8de'}} href='/client'>&ensp;Clients</Nav.Link></Nav.Item>
                  }
                  {(user.role === 1 || user.role === 8 || user.role === 2) &&
                    <Nav.Item className='d-lg-none alignment'><SolutionIcon height='1.5em' width='1.5em' fill='#FFA500'/><Nav.Link style={{color:'#d8d8de'}} href='/solutions'>&ensp;Solutions</Nav.Link></Nav.Item>
                  }
                  {(user.role === 1 || user.role === 8) &&
                    <Nav.Item className='d-lg-none alignment'><ProviderIcon height='1.5em' width='1.5em' fill='#FFA500'/><Nav.Link style={{color:'#d8d8de'}} href='/providers'>&ensp;Service Providers</Nav.Link></Nav.Item>
                  }
                  {(user.role === 1) &&
                    <Nav.Item className='d-lg-none alignment'><LicenseIcon height='1.5em' width='1.5em' fill='#FFA500'/><Nav.Link style={{color:'#d8d8de'}}  href='/license'>&ensp;Licenses</Nav.Link></Nav.Item>
                  }
                  <Nav.Item className='d-lg-none alignment'><DealerIcon height='1.5em' width='1.5em' fill='#FFA500'></DealerIcon><Nav.Link style={{color:'#d8d8de'}} href='/dealers'>&ensp;Dealers</Nav.Link></Nav.Item>
                  {(user.role === 1 || user.role === 3 || user.role === 5 || user.role === 8) &&
                    <Nav.Item className='d-lg-none alignment'><BillingIcon height='1.5em' width='1.5em' fill='#FFA500'></BillingIcon><Nav.Link style={{color:'#d8d8de'}}  href='/billing'>&ensp;Billing</Nav.Link></Nav.Item>
                  } 
                </Fragment>
              }
              <Nav.Item className='d-lg-none alignment'><TermsIcon height='1.5em' width='1.5em' fill='#FFA500'></TermsIcon><Nav.Link style={{color:'#d8d8de'}} href='/terms'>&ensp;Terms Of Use</Nav.Link></Nav.Item>
              <Nav.Item className='d-lg-none alignment'><TermsIcon height='1.5em' width='1.5em' fill='#FFA500'></TermsIcon><Nav.Link style={{color:'#d8d8de'}} href='/privacy'>&ensp;Privacy</Nav.Link></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Styles>
    </Container>
  )
}

export default NavigationBar


